import { RedlMetadataContext } from "context/REDL/RedlMetadataProvider";
import { useContext } from "react";
import __partial from "lodash/partial";

export const useRedlMetadata = (pathname, routerQuery) => {
    const {
        setRedlPageMetadata,
        getRedlPageMetadata,
        getRedlBlocMetadata,
        setRedlPageRawLayout,
        getRedlPageRawLayout,
        getRedlBlocRawLayout,
        moveRedlBloc,
        addRedlBloc,
        editRedlBloc,
        deleteRedlBloc,
        setRedlNotRenderableInfo,
        setRedlRenderableInfo
    } = useContext(RedlMetadataContext);

    const redlParamId = routerQuery?.['redl-id'];
    const redlParamTipologia = routerQuery?.['redl-tipologia'];

    return {
        setRedlPageMetadata: __partial(setRedlPageMetadata, pathname),
        getRedlPageMetadata: __partial(getRedlPageMetadata, pathname),
        getRedlBlocMetadata: __partial(getRedlBlocMetadata, pathname),
        setRedlPageRawLayout: __partial(setRedlPageRawLayout, pathname),
        getRedlPageRawLayout: __partial(getRedlPageRawLayout, pathname),
        getRedlBlocRawLayout: __partial(getRedlBlocRawLayout, pathname),
        moveRedlBloc: __partial(moveRedlBloc, pathname),
        addRedlBloc: __partial(addRedlBloc, pathname),
        editRedlBloc: __partial(editRedlBloc, pathname),
        deleteRedlBloc: __partial(deleteRedlBloc, pathname),
        setRedlNotRenderableInfo,
        setRedlRenderableInfo: __partial(setRedlRenderableInfo, pathname),
        redlParamId,
        redlParamTipologia
    };
};