import { getData } from '@portal-internet/bff';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import crypto from 'crypto';
import __indexOf from 'lodash/indexOf';
import __pullAt from 'lodash/pullAt';
import __cloneDeep from 'lodash/cloneDeep';
import __set from 'lodash/set';
import RedlNotRenderableInfo from 'components/REDL/RedlNotRenderableInfo';

export const RedlMetadataContext = createContext({
    setRedlPageMetadata: (pathname, allData, structure) => { },
    getRedlPageMetadata: (pathname) => { },
    getRedlBlocMetadata: (pathname, blocIdx) => { },
    setRedlPageRawLayout: (pathname, rawLayout) => { },
    getRedlPageRawLayout: (pathname) => { },
    getRedlBlocRawLayout: (pathname, blocIdx) => { },
    moveRedlBloc: (pathname, blocIdx, newIndex) => { },
    addRedlBloc: (pathname, newBlock, newIndex) => { },
    editRedlBloc: (pathname, newBlock, newIndex) => {
    },
    deleteRedlBloc: (pathname, blocIdx) => { },
    setRedlNotRenderableInfo: (dataId, rootIndex, componentName) => { },
    setRedlRenderableInfo: (pathname, newStructure, isReset) => { },
    resetRedlNotRenderableInfo: () => { },
});

const RedlMetadataProvider = (props) => {
    const [pageMetadata, setPageMetadata] = useState({});
    const [pageRawLayout, setPageRawLayout] = useState({});
    const [pageNotRenderableInfo, setPageNotRenderableInfo] = useState({});
    const [showNotRenderableInfo, setShowNotRenderableInfo] = useState(false);

    const resetRedlNotRenderableInfo = () => {
        setPageNotRenderableInfo({});
        setShowNotRenderableInfo(false);
    };

    const router = useRouter();
    useEffect(() => {
        const handleRouteChange = (url) => {
            resetRedlNotRenderableInfo();
        };
        router.events.on('routeChangeStart', handleRouteChange);
        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [router.events]);

    const publicApi = useMemo(() => {
        const setRedlPageMetadata = (pathname, allData = [], structure, components, layoutURLHost) => {
            setPageMetadata((prevState) => {
                if (!prevState[pathname]) {
                    return {
                        ...prevState,
                        [pathname]: {
                            allData: [...allData],
                            structure: [...structure],
                            components: [...components],
                            layoutURLHost,
                            editId: 0,
                            isReset: true,
                        }
                    };
                }
                return prevState;
            });
        };

        const setRedlPageRawLayout = (pathname, rawLayout) => {
            setPageRawLayout((prevState) => {
                if (!prevState[pathname]) {
                    return {
                        ...prevState,
                        [pathname]: rawLayout
                    };
                }
                return prevState;
            });
        };

        const getRedlPageMetadata = (pathname) => {
            return pageMetadata[pathname];
        };

        const getRedlPageRawLayout = (pathname) => {
            return pageRawLayout[pathname];
        };

        const _getRedlBlocCurrentIdx = (pathname, blocIdx) => {
            const structure = pageMetadata[pathname]?.structure;
            if (!structure) return [null, -1];
            const zonaEditableChildren = structure.find((c) => c.name === 'ZonaEditable')?.children;
            if (!zonaEditableChildren) return [null, -1];

            return [zonaEditableChildren, __indexOf(zonaEditableChildren, zonaEditableChildren.find((element) => element.blocIdx === blocIdx))];
        };

        const getRedlBlocMetadata = (pathname, blocIdx) => {
            const [structure, currentIdx] = _getRedlBlocCurrentIdx(pathname, blocIdx);
            if (currentIdx < 0) return;

            return structure[currentIdx];
        };

        const _addEditablePropsToBlocRawLayout = (structure, allComponentsEditableProps) => {
            if (!structure) return
            if (allComponentsEditableProps[structure.name]) { structure.editableProps = allComponentsEditableProps[structure.name]; }
            if (Array.isArray(structure.children)) {
                structure.children.map((component) => {
                    _addEditablePropsToBlocRawLayout(component, allComponentsEditableProps)
                });
            }
            return structure;
        };

        const getRedlBlocRawLayout = (pathname, blocIdx) => {
            const [, currentIdx] = _getRedlBlocCurrentIdx(pathname, blocIdx);
            const rawLayout = pageRawLayout[pathname];
            const structure = rawLayout?.structure || [];
            const zonaEditable = structure.find((c) => c.name === 'ZonaEditable')?.children;
            if (currentIdx < 0 || !structure || !zonaEditable) return;
            const structureWithoutEditableProps = __cloneDeep(zonaEditable[currentIdx]);
            return _addEditablePropsToBlocRawLayout(structureWithoutEditableProps, pageRawLayout[pathname]?.editableProps || {});
        };

        const _renderRawLayoutOnServer = async (pathname, newStructure, isReset) => {
            resetRedlNotRenderableInfo();
            const rawLayout = { ...pageRawLayout[pathname], structure: newStructure };
            const rawLayoutMD5 = crypto.createHash('md5').update(JSON.stringify(rawLayout)).digest('hex');
            let slugs = pathname.split('/');
            slugs[0] = 'web';
            const data = await getData({
                queryKey: { type: 'layout', context: { query: { editMode: true } }, options: { slugs: slugs, layout: rawLayoutMD5 } },
                layoutOnTheFly: rawLayout
            });
            let renderedByServer = data?.props;
            const structureRenderedByServer = renderedByServer?.layout?.structure;
            setPageMetadata((prevState) => {
                if (prevState[pathname]) {
                    return {
                        ...prevState,
                        [pathname]: {
                            ...prevState[pathname],
                            structure: structureRenderedByServer,
                            editId: prevState[pathname].editId + 1,
                            isReset: isReset || false,
                        }
                    };
                }
                return prevState;
            });
        };

        const _moveRedlBlocOnPageRawLayout = (pathname, currentIdx, newIdx) => {
            const structure = pageRawLayout[pathname]?.structure;
            if (!structure) return;
            const zonaEditableChildren = structure.find((c) => c.name === 'ZonaEditable')?.children;
            const zonaEditableIndex = structure.findIndex((c) => c.name === 'ZonaEditable');
            if (!zonaEditableIndex) return;

            const removedRedlBloc = __pullAt(zonaEditableChildren, currentIdx)[0];
            const newZonaEditableChildren = newIdx < zonaEditableChildren.length
                ? [...zonaEditableChildren.slice(0, newIdx), removedRedlBloc, ...zonaEditableChildren.slice(newIdx)]
                : [...zonaEditableChildren, removedRedlBloc];
            const newStructure = [...structure];
            newStructure[zonaEditableIndex].children = newZonaEditableChildren;
            setPageRawLayout((prevState) => {
                return {
                    ...prevState,
                    [pathname]: {
                        ...prevState[pathname],
                        structure: newStructure
                    }
                };
            });
            _renderRawLayoutOnServer(pathname, newStructure);
        };

        const _addRedlBlocOnPageRawLayout = (pathname, newBlock, newIdx) => {
            const structure = pageRawLayout[pathname]?.structure;
            if (!structure) return;
            const zonaEditableChildren = structure.find((c) => c.name === 'ZonaEditable')?.children;
            const zonaEditableIndex = structure.findIndex((c) => c.name === 'ZonaEditable');
            if (!zonaEditableIndex) return;

            const newZonaEditableChildren = [...zonaEditableChildren.slice(0, newIdx), newBlock, ...zonaEditableChildren.slice(newIdx)];
            const newStructure = [...structure];
            newStructure[zonaEditableIndex].children = newZonaEditableChildren;
            setPageRawLayout((prevState) => {
                return {
                    ...prevState,
                    [pathname]: {
                        ...prevState[pathname],
                        structure: newStructure
                    }
                };
            });
            _renderRawLayoutOnServer(pathname, newStructure);
        };

        const _editRedlBlocOnPageRawLayout = (pathname, newBlock, newIdx) => {
            const structure = pageRawLayout[pathname]?.structure;
            if (!structure) return;
            const zonaEditableChildren = structure.find((c) => c.name === 'ZonaEditable')?.children;
            const zonaEditableIndex = structure.findIndex((c) => c.name === 'ZonaEditable');
            if (!zonaEditableIndex) return;

            const newZonaEditableChildren = [...zonaEditableChildren.slice(0, newIdx), newBlock, ...zonaEditableChildren.slice(newIdx + 1)];
            const newStructure = [...structure];
            newStructure[zonaEditableIndex].children = newZonaEditableChildren;
            setPageRawLayout((prevState) => {
                return {
                    ...prevState,
                    [pathname]: {
                        ...prevState[pathname],
                        structure: newStructure
                    }
                };
            });
            _renderRawLayoutOnServer(pathname, newStructure);
        };

        const _deleteRedlBlocOnPageRawLayout = (pathname, currentIdx) => {
            const structure = pageRawLayout[pathname]?.structure;
            if (!structure) return;

            const zonaEditableChildren = structure.find((c) => c.name === 'ZonaEditable')?.children;
            const zonaEditableIndex = structure.findIndex((c) => c.name === 'ZonaEditable');
            if (zonaEditableIndex === -1 || currentIdx < 0 || currentIdx >= zonaEditableChildren.length) return;

            const newZonaEditableChildren = [
                ...zonaEditableChildren.slice(0, currentIdx),
                ...zonaEditableChildren.slice(currentIdx + 1)
            ];

            const newStructure = [...structure];
            newStructure[zonaEditableIndex].children = newZonaEditableChildren;

            setPageRawLayout((prevState) => {
                return {
                    ...prevState,
                    [pathname]: {
                        ...prevState[pathname],
                        structure: newStructure
                    }
                };
            });

            _renderRawLayoutOnServer(pathname, newStructure);
        };

        const moveRedlBloc = (pathname, blocIdx, newIndex) => {
            const [structure, currentIdx] = _getRedlBlocCurrentIdx(pathname, blocIdx);
            if (currentIdx < 0) return;

            const newIdx = newIndex < 0 ? 0 : newIndex >= structure.length ? structure.length - 1 : newIndex;
            _moveRedlBlocOnPageRawLayout(pathname, currentIdx, newIdx);
        };

        const addRedlBloc = (pathname, newBlock, newIndex) => {
            const structure = pageMetadata[pathname]?.structure;
            const newIdx = newIndex < 0 ? 0 : newIndex >= structure.length ? structure.length - 1 : newIndex;
            _addRedlBlocOnPageRawLayout(pathname, newBlock, newIdx);
        };

        const editRedlBloc = (pathname, newBlock, newIndex) => {
            const structure = pageMetadata[pathname]?.structure;
            const newIdx = newIndex < 0 ? 0 : newIndex >= structure.length ? structure.length - 1 : newIndex;
            _editRedlBlocOnPageRawLayout(pathname, newBlock, newIdx);
        };

        const deleteRedlBloc = (pathname, blocIdx) => {
            const [_structure, currentIdx] = _getRedlBlocCurrentIdx(pathname, blocIdx);
            if (currentIdx < 0) return;

            _deleteRedlBlocOnPageRawLayout(pathname, currentIdx);
        };

        const setRedlNotRenderableInfo = (dataId, rootIndex, componentName) => {
            setPageNotRenderableInfo((prevState) => {
                const newState = { ...prevState };
                __set(newState, [dataId, rootIndex], componentName);
                return newState;
            });
            setShowNotRenderableInfo(true);
        };

        const setRedlRenderableInfo = (pathname, newStructure, isReset) => {
            if (newStructure) {
                setPageRawLayout((prevState) => {
                    return {
                        ...prevState,
                        [pathname]: {
                            ...prevState[pathname],
                            structure: __cloneDeep(newStructure)
                        }
                    };
                });
                _renderRawLayoutOnServer(pathname, newStructure, isReset);
            }
        };

        return {
            setRedlPageMetadata,
            getRedlPageMetadata,
            getRedlBlocMetadata,
            setRedlPageRawLayout,
            getRedlPageRawLayout,
            getRedlBlocRawLayout,
            moveRedlBloc,
            addRedlBloc,
            editRedlBloc,
            deleteRedlBloc,
            setRedlNotRenderableInfo,
            setRedlRenderableInfo
        };
    }, [pageMetadata, pageRawLayout]);

    return (
        <RedlMetadataContext.Provider value={publicApi}>
            {showNotRenderableInfo && <RedlNotRenderableInfo pageNotRenderableInfo={pageNotRenderableInfo} />}
            {props.children}
        </RedlMetadataContext.Provider>
    );
};

export default RedlMetadataProvider;