import React from 'react';
import NotFound from 'components/3cat/NotFound';
import { shouldSendLog } from 'utils/pixelNegreHelper';
import { getPixelNegreUrl } from '@portal-internet/core';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.hideError = props.hideError;
        this.state = { hasError: false }; // Define a state variable to track whether is an error or not
    }
    static getDerivedStateFromError(error) {
        return { hasError: true }; // Update state so the next render will show the fallback UI
    }
    componentDidCatch(error, errorInfo) {
        // No mostrar l'error a producció
        if (process.env.isDev && process.env.proxy) console.log({ error, errorInfo });
        if (shouldSendLog(error.toString())) {
            const marcatgeParams = {
                app: '3CAT_WEB',
                version: '1.2412.3',
                error: error,
                errorInfo: JSON.stringify(errorInfo)
            };
            const queryString = Object.keys(marcatgeParams)
                .map((key) => {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(marcatgeParams[key]);
                })
                .join('&');
            new Image().src = getPixelNegreUrl(queryString);
        }
    }
    render() {
        // if the error is thrown you can render any custom fallback UI
        if (this.state.hasError) {
            if (this.hideError) return null; // Hide error message
            const statusCode = 500;
            const errorMessage = 'Error intern';
            return <NotFound code={statusCode} text={errorMessage} cleanUrlParams={true} />;
        }
        return this.props.children; // Return children components in case of no error
    }
}

export default ErrorBoundary;
