import { LogLevel } from '@azure/msal-browser';
import { b2cPolicies } from './policies';
import { apiConfig } from './apiConfig';

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

let jitConfigAzureClient, jitConfigAzureRedirect;

if (typeof window !== 'undefined') {
    jitConfigAzureClient = window.jitConfig.azureClient;
    jitConfigAzureRedirect = window.jitConfig.azureRedirect;
}

const msalConfig = {
    auth: {
        clientId: jitConfigAzureClient, //Mandatory field.
        authority: b2cPolicies.authorities.signUpSignInCP.authority, // Choose sign-up/sign-in user-flow as your default.
        knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
        redirectUri: jitConfigAzureRedirect,
        postLogoutRedirectUri: jitConfigAzureRedirect
    },
    cache: {
        cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false // If you wish to store cache items in cookies as well as browser cache, set this to "true".
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPersonalData) => {
                const msalLoggingDisabled = true;
                if (containsPersonalData || msalLoggingDisabled) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
const loginRequest = {
    scopes: ['openid', ...apiConfig.b2cScopes]
};

/**
 * Scopes you add here will be used to request a token from Azure AD B2C to be used for accessing a protected resource.
 * To learn more about how to work with scopes and resources, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
const tokenRequest = {
    scopes: [...apiConfig.b2cScopes], // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
    forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/beta/me',
    scopes: ['User.Read']
};

export { msalConfig, loginRequest, tokenRequest, graphConfig };
