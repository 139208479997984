import Boto from '../Boto/Boto';
import styles from './notfound.module.scss';

export default function NotFound(props) {
    const { code, text, cleanUrlParams } = props;

    return (
        <div className={styles.notfound} data-testid={`error-${code}`}>
            <h3 className={styles.titol}>{text}</h3>
            <p className={styles.codi}>Codi d&apos;error: {code}</p>
            <p className={styles.text}>
                La pàgina que busqueu no existeix o s&apos;ha produït un altre error. Disculpeu les molèsties.
            </p>
            <Boto
                tag='a'
                text="Tornar a la pàgina d'inici"
                className={styles.boto}
                route={{ href: '/3catinfo' }}
                cleanUrlParams={cleanUrlParams}
            />
        </div>
    );
}

NotFound.defaultProps = {
    text: '',
    cleanUrlParams: false
};
