import clsx from 'clsx';
import styles from './logindty.module.scss';
import __isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { useDelivertyUser } from 'hooks/useDelivertyUser';

export default function LoginDty(props) {
    const { error, missatge } = props;
    const { loginUser } = useDelivertyUser();

    const [empty, setEmpty] = useState(true);
    const [user, setUserName] = useState();
    const [clau, setPassword] = useState();

    const changeState = (value) => {
        if (_.isEmpty(value)) {
            setEmpty(true);
        } else {
            setEmpty(false);
        }
    };
    const setActive = (value) => {
        setEmpty(false);
    };
    const className = clsx({
        'shadow': true,
        [styles.rLoginWrapper]: true,
        'd-flex': true
    });
    const labelClassName = clsx({
        [styles.rCampLabel]: true,
        [styles.empty]: empty,
    });
    const rCampClassName = clsx({
        [styles.rCamp]: true,
        [styles.rCampEmpty]: !empty,
        [styles.inputCamp]: true,
        [styles.valueError]: !_.isEmpty(error)
    });
    const nameClassName = clsx({
        [styles.rName]: true,
    })
    const errorClassName = clsx({
        [styles.rLoginError]: true,
        'login-error fs-5': true,
        'login-error-test': true
    });
    const logoImg = clsx('/_resources/logos/dty-logo.svg');
    const doLogin = () => {
        loginUser({ user, clau });
    }
    const _renderMissatge = () => {
        if (missatge) {
            return (<div className={errorClassName}>{missatge}</div>);
        }
        return (<div className={nameClassName}>Login deliverty</div>)
    }
    return (
        <div className={styles.mask}>
            <div className={className}>
                <img className={styles.imDtyLogo} src={logoImg} width='66' height='69' />
                {_renderMissatge()}
                <div className={styles.rFormLogin}>
                    <div className={rCampClassName}>
                        <label className={labelClassName}>Usuari</label>
                        <input
                            type='text'
                            id='Usuari'
                            onBlur={(e) => changeState(e.target.value)}
                            onFocus={(e) => setActive(e.target.value)}
                            className={styles.rCampInput}
                            onChange={(e) => setUserName(e.target.value)}
                        />
                    </div>
                    <div className={rCampClassName}>
                        <label className={labelClassName}>Password</label>
                        <input
                            type='password'
                            id='Password'
                            onBlur={(e) => changeState(e.target.value)}
                            onFocus={(e) => setActive(e.target.value)}
                            className={styles.rCampInput}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className={styles.marginAuto}>
                        <button className={styles.rCampButton} type='submit' onClick={doLogin}>
                            Accedeix
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
