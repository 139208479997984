/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
let jitConfigAzureAppHost;

if (typeof window !== 'undefined') {
    jitConfigAzureAppHost = window.jitConfig.azureAppHost;
}

const b2cPolicies = {
    names: {
        signUpSignIn: 'B2C_1_signupsignin1',
        editProfile: 'B2C_1_profileediting1'
    },
    authorities: {
        editProfileCP: {
            authority: jitConfigAzureAppHost + '/B2C_1A_PROFILEEDIT_1'
        },
        changeEmail: {
            authority: jitConfigAzureAppHost + '/B2C_1A_CHANGESIGNINNAME_1'
        },
        deleteMyAccount: {
            authority: jitConfigAzureAppHost + '/B2C_1A_DELETEMYACCOUNT_1'
        },
        signUpSignInCP: {
            authority: jitConfigAzureAppHost + '/B2C_1A_SIGNUPSIGNIN_1'
        },
        forgotPasswordCP: {
            authority: jitConfigAzureAppHost + '/B2C_1A_PASSWORDRESET_1'
        }
    },
    authorityDomain: jitConfigAzureAppHost
};

export { b2cPolicies };
